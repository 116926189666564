define("cac-components/components/image-slide/send/component", ["exports", "cac-components/components/image-slide/send/template", "cac-components/helpers/transform-image"], function (_exports, _template, _transformImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    layout: _template.default,
    transformation: null,
    imagesProperties: Ember.computed('images.[]', function () {
      var _this = this;

      return Ember.get(this, 'images').map(function (image) {
        return _this.getImageProperties(Ember.get(image, 'url') || image, Ember.get(_this, 'description'));
      });
    }),
    getImageProperties: function getImageProperties(image, description) {
      var matches = unescape(image).match(/,w_([0-9]+),h_([0-9]+)\./);
      debugger;

      if (matches) {
        return {
          src: (0, _transformImage.transformImage)(image, Ember.get(this, 'transformation')),
          w: matches[1],
          h: matches[2],
          title: description
        };
      } else {
        return {
          src: image,
          w: 500,
          h: 500,
          title: description
        };
      }
    }
  });

  _exports.default = _default;
});